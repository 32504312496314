import React from "react";
import styled from "styled-components";
import { GoSearch } from "react-icons/go";
import { translate } from "../services/i18n";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./filter.css";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white;
  box-shadow: none;
  outline: none;
  padding: 0.5rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #888888aa;
`;

const Filters = (props) => {
  const { searchValue, setSearchValue } = props;

  return (
    <>
      {
        <Container>
          <Header
            style={{
              alignItems: "center",
              paddingLeft: "1rem",
              paddingTop: "0.3rem",
              paddingBottom: "0.3rem",
            }}
          >
            <div
              style={{
                color: "white",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "44.75rem",
                  gap: "1rem",
                  backgroundColor: "#605a5a",
                  opacity: "0.5",
                  padding: "0 0.5rem 0rem 0.5rem",
                }}
              >
                <GoSearch />
                <SearchInput
                  className="white-placeholder"
                  type="text"
                  value={searchValue}
                  placeholder={translate("filters.search")}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div
                style={{
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                  color: "#B81818",
                  fontWeight: "medium",
                }}
              >
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#B81818",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></span>
                {translate("filters.unable").toUpperCase()}
              </div>
              <div
                style={{
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                  color: "#0A8B8B",
                }}
              >
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#0A8B8B",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></span>
                {translate("filters.able").toUpperCase()}
              </div>
            </div>
          </Header>
        </Container>
      }
    </>
  );
};

export default Filters;
