import "./table.css";
import React, { useState } from "react";
import styled from "styled-components";
import { translate } from "../services/i18n";

const RankTable = styled.div`
  table {
    min-width: fit-content;
    width: 100%;
  }

  th {
    background-color: #eaeaea;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
  }

  @media (min-width: 780px) {
    max-height: 70vh;
  }
`;

const Table = (props) => {
  const { data, search } = props;
  const [sortKey, setSortKey] = useState("mount_points");
  const [sortOrder, setSortOrder] = useState(false); // true for ascending, false for descending

  if (!Array.isArray(data) || !data[0]) return <></>;

  const dataWithOriginalPosition = data.map((item, index) => ({
    ...item,
    originalPosition: index + 1,
  }));

  let filteredData = search
    ? dataWithOriginalPosition.filter((row) =>
        row.charname.toLowerCase().includes(search.toLowerCase())
      )
    : dataWithOriginalPosition;

  // Apply sorting if sortKey is set
  if (sortKey) {
    filteredData = filteredData.sort((a, b) =>
      sortOrder ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey]
    );
  }

  const handleSortChange = (key) => {
    if (sortKey === key) {
      setSortOrder(!sortOrder); // If the same key is clicked, reverse the sort order
    } else {
      setSortKey(key);
      setSortOrder(true); // Reset sort order to ascending when key changes
    }
  };

  return (
    <RankTable>
      <table style={{ color: "#444444" }}>
        <thead>
          <tr>
            <th>{translate("categories.position").toUpperCase()}</th>
            {Object.keys(data[0]).map((key) => {
              if (
                key === "level_points" ||
                key === "vitality_points" ||
                key === "quality_points" ||
                key === "kill_points" ||
                key === "participation_points" ||
                key === "conclusion_points"
              )
                return null;
              switch (key) {
                case "charname":
                  return (
                    <th key={key}>
                      {translate("categories.name").toUpperCase()}
                    </th>
                  );
                case "pvp_points":
                  return (
                    <th
                      key={key}
                      onClick={() => handleSortChange(key)}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    >
                      {translate("categories.pvpPoints").toUpperCase()}
                      {sortKey === key && (sortOrder ? " ▲" : " ▼")}
                    </th>
                  );
                case "mount_points":
                  return (
                    <th
                      key={key}
                      style={{
                        textShadow: "2px 2px 2px rgba(0, 0, 0, 0.3)",
                        WebkitTextStroke: "1px black",
                      }}
                      onClick={() => handleSortChange(key)}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    >
                      {translate("categories.mountPoints").toUpperCase()}
                      {sortKey === key && (sortOrder ? " ▲" : " ▼")}
                    </th>
                  );
                default:
                  break;
              }
              return <th key={key}>{key}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => {
            return (
              <tr
                key={index}
                style={{
                  backgroundColor:
                    row.originalPosition <= 150
                      ? "#FFCE51"
                      : row.originalPosition <= 500
                      ? "#E4E2E2"
                      : "#D7AC85",
                }}
              >
                <td
                  style={{
                    color:
                      row.originalPosition > 3
                        ? "#444444"
                        : row.originalPosition === 3
                        ? "#859B62"
                        : row.originalPosition === 2
                        ? "#4E9D60"
                        : "#228538",
                  }}
                >
                  {row.originalPosition}º
                </td>

                {Object.keys(row)
                  .filter(
                    (key) =>
                      key !== "originalPosition" &&
                      key !== "level_points" &&
                      key !== "vitality_points" &&
                      key !== "quality_points" &&
                      key !== "kill_points" &&
                      key !== "participation_points" &&
                      key !== "conclusion_points"
                  )
                  .map((value, valueIndex) => {
                    const isAbleToWithdraw =
                      row["mount_points"] >= 500
                        ? row["pvp_points"] >= 100
                          ? true
                          : false
                        : false;
                    return (
                      <td
                        key={value}
                        className={
                          value !== "charname" ? "has-details" : undefined
                        }
                      >
                        {row.originalPosition <= 3 &&
                        value !== "mount_points" &&
                        value !== "pvp_points" ? (
                          <strong
                            style={{
                              color:
                                row.originalPosition === 3
                                  ? "#859B62"
                                  : row.originalPosition === 2
                                  ? "#4E9D60"
                                  : "#228538",
                            }}
                          >
                            {row[value]}
                          </strong>
                        ) : value === "mount_points" ||
                          value === "pvp_points" ? (
                          <div
                            style={{
                              color: isAbleToWithdraw ? "#0A8B8B" : "#B81818",
                              fontWeight: 600,
                            }}
                          >
                            {row[value]}
                          </div>
                        ) : (
                          row[value]
                        )}
                        <span className="details">
                          <span style={{ fontSize: "14px" }}>
                            {translate("categories.totalScore")}:{" "}
                          </span>
                          <br />
                          <br />
                          {value === "mount_points" ? (
                            <div>
                              {translate("categories.level_points")}:{" "}
                              {row["level_points"]}
                              <br /> {translate(
                                "categories.quality_points"
                              )}: {row["quality_points"]}
                              <br /> {translate(
                                "categories.vitality_points"
                              )}: {row["vitality_points"]}
                            </div>
                          ) : (
                            <div>
                              {translate("categories.kill_points")}:{" "}
                              {row["kill_points"]}
                              <br />{" "}
                              {translate(
                                "categories.participation_points"
                              )}: {row["participation_points"]}
                              <br /> {translate("categories.conclusion_points")}
                              : {row["conclusion_points"]}
                            </div>
                          )}
                        </span>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </RankTable>
  );
};

export default Table;
