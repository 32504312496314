export const pt = {
  tag: "pt-BR",
  header: {
    title: "Servidor de Temporada: Loki Ranking",
    description: "Descubra os jogadores mais insanos do servidor",
    description2: "Jogadores que estiverem inativos por mais de 3 meses serão removidos do ranking. Caso o jogador faça login no jogo, ele estará automaticamente qualificado a participar do ranking.",
    updating: "O ranking está sendo atualizado. Por favor, volte mais tarde.",
  },
  categories: {
    title: "Categorias",
    level: "Nível",
    kills: "Abates",
    deaths: "Mortes",
    points: "Pontos",
    name: "Jogador",
    position: "Rank",
    raWins: "Vitórias na Arena Real",
    raKills: "Abates na Arena Real",
    seasonLevel: "Servidor de Temporada",
    fameGuild: "Fame Guild",
    pvpPoints: "Pontos PvP",
    mountPoints: "Pontos de Montaria",
    lokiScore: "Pontos Loki",
    totalScore: "Score definido pelo somatório",
    level_points: "Nível",
    quality_points: "Qualidade",
    vitality_points: "Vitalidade",
    kill_points: "Abates",
    participation_points: "Participações",
    conclusion_points: "Conclusões",
  },
  filters: {
    date: "Filtrar por Data",
    search: "Pesquisar por nickname",
    cumulative: "Pontuação Total",
    day: "Visualização do dia ",
    able: "Valor Habilitado para Recompensa",
    unable: "Valor Desabilitado para Recompensa",
  },
  remove: "Remover todos os filtros",
  activefilter: "Filtro Ativo",
};
