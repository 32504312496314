import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Rank from "./rank";
import Filters from "./filters";
import axios from "axios";

const DivBody = styled.div`
  min-height: 89vh;
  width: 100%;
  @media (min-width: 820px) {
    display: flex;
  }
`;
const DivMain = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const Body = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const url =
        "https://tfp4kjnrawiqxah4nc5a5jd2lq0zwdjq.lambda-url.us-east-1.on.aws/loki-component-rank";
      const response = await axios.get(url);
      setData(response.data);
      await wait(1000); // You can remove this if unnecessary
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DivBody>
      <DivMain>
        <Filters searchValue={searchValue} setSearchValue={setSearchValue} />
        <Rank loading={loading} data={data} search={searchValue} />
        {/* <p style={{ color: "white" }}>{translate("header.updating")}</p> */}
      </DivMain>
    </DivBody>
  );
};

export default Body;

const wait = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
