import React from "react";
import styled from "styled-components";
import { translate } from "../services/i18n";

const Bar = styled.div`
  color: white;
  box-sizing: border-box;
  width: 100%;
  background-color: #373847ee;
  padding: 20px;

  h1 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const Navbar = () => {
  return (
    <Bar>
      <h1>{translate("header.title", { server: "Season" })}</h1>
      <p>{translate("header.description")}</p><br/>
      <p>{translate("header.description2")}</p>
    </Bar>
  );
};

export default Navbar;
