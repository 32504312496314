export const en = {
  tag: "en-US",
  header: {
    title: "Season Server: Loki Ranking",
    description: "Discover the most insane players on the server",
    description2: "Players who have been inactive for more than 3 months will be removed from the ranking. If the player log in the game, they will automatically qualify to participate in the ranking.",
    updating: "The ranking is being updated. Please check back later.",
  },
  categories: {
    title: "Categories",
    level: "Level",
    kills: "Kills",
    deaths: "Deaths",
    points: "Points",
    name: "Player",
    position: "Rank",
    raWins: "Royal Arena Wins",
    raKills: "Royal Arena Kills",
    seasonLevel: "Season Server",
    fameGuild: "Fame Guild",
    pvpPoints: "PVP Score",
    mountPoints: "Mount Score",
    lokiScore: "Loki Score",
    totalScore: "Score defined by the sum",
    level_points: "Level",
    quality_points: "Quality",
    vitality_points: "Vitality",
    kill_points: "Kills",
    participation_points: "Participations",
    conclusion_points: "Conclusions",
  },
  filters: {
    date: "Filter by Date",
    search: "Search by nickname",
    cumulative: "Total Score",
    day: "View of ",
    able: "Able for Reward Retrieval",
    unable: "Unable for Reward Retrieval",
  },
  remove: "Remove all filters",
  activefilter: "Active Filter",
};
